// types
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

// project imports
import { RootState, dispatch } from '../index'
import { IAppItem, getAppList } from 'apis/app'
import { OptionObj } from 'types'
import { IGameItem, getGameId } from 'apis/game'

// initial state
const initialState: {
    appNameOptions: IAppItem[]
    gameIdOptions: IGameItem[]
} = {
    appNameOptions: [],
    gameIdOptions: [],
}

// ==============================|| SLICE - MENU ||============================== //

const asyncOptions = createSlice({
    name: 'asyncOptions',
    initialState,
    reducers: {
        setAppNameOptions(state, action) {
            state.appNameOptions = action.payload
        },
        setGameIdOptions(state, action) {
            state.gameIdOptions = action.payload
        },
    },
})

export default asyncOptions.reducer

const selectAppNameOptions = (state) => state.asyncOptions.appNameOptions

export const selectAppListOptions = createSelector(
    selectAppNameOptions,
    (appNameOptions): OptionObj[] => {
        return appNameOptions.map(({ app }) => ({
            label: app,
            value: app,
        }))
    }
)
// for cache purpose
const gameIdOptions = (state) => state.asyncOptions.gameIdOptions
export const selectGameIdOptions = createSelector(
    gameIdOptions,
    (gameIdOptions): OptionObj[] => {
        return gameIdOptions.map(({ game_id, title }) => ({
            label: title,
            value: game_id,
        }))
    }
)

export const fetchAppListOptions = createAsyncThunk(
    'asyncOptions/fetchAppListOptions',
    async (__, { getState }) => {
        const state = getState() as RootState
        if (state.asyncOptions.appNameOptions.length > 0) {
            return false
        }
        try {
            const res = await getAppList({
                page: 1,
                limit: 100,
            })
            const appListData = res.data.list || []
            // return appListData
            dispatch(asyncOptions.actions.setAppNameOptions(appListData))
        } catch (error) {
            console.error(error)
        }
    }
)

export const fetchGameIdOptions = createAsyncThunk(
    'asyncOptions/fetchGameIdOptions',
    async (__, { getState }) => {
        const state = getState() as RootState
        if (state.asyncOptions.gameIdOptions.length > 0) {
            return false
        }
        try {
            const res = await getGameId()
            const gameConfigData = res.data.list || []
            dispatch(asyncOptions.actions.setGameIdOptions(gameConfigData))
        } catch (error) {
            console.error(error)
        }
    }
)
