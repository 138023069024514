export const groupByOption = [
    {
        label: 'No Group',
        value: 'NoGroup',
    },
    {
        label: 'Game',
        value: 'Game',
    },
    {
        label: 'Player',
        value: 'Player',
    },
]
export const periodOption = [
    {
        label: 'By Day',
        value: 'day',
    },
    {
        label: 'By Week',
        value: 'week',
    },
    {
        label: 'By Month',
        value: 'month',
    },
]
