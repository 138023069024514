// third party
import { number, object, string, date, ref } from 'yup'
import { useFormik } from 'formik'

// project imports
import CommonForm, { IField } from 'ui-component/form'
import MainCard from 'ui-component/cards/MainCard'
import { ITransactionListReq, ITransactionListReqForm } from 'apis/transaction'
import { currencyOptions } from 'utils/currency'
import { convertDatePickerToString } from 'utils/utils'
import { useEffect, useState } from 'react'
import {
    fetchAppListOptions,
    fetchGameIdOptions,
    selectAppListOptions,
    selectGameIdOptions,
} from 'store/slices/asyncOptions'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'store'
import { OptionObj } from 'types'
import dayjs from 'dayjs'
import { ExpandMore } from 'ui-component/ExpandMore'
import { Collapse } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
interface IProps {
    onAction: (values: ITransactionListReq) => void
}

const transactionTypeOption: OptionObj[] = [
    {
        label: 'Bet',
        value: 'bet',
    },
    {
        label: 'Win',
        value: 'win',
    },
    {
        label: 'Rollabck',
        value: 'rollabck',
    },
]
const validationSchema = object({
    app: string().trim().required(),
    amount: object({
        min: number().lessThan(ref('max')).nullable(),
        max: number().moreThan(ref('min')).nullable(),
    }),
    processedAt: object({
        start: date().nullable(),
        end: date().nullable(),
    }),
})

// ==============================|| KANBAN BACKLOGS - ADD STORY ||============================== //

const ListFilter = ({ onAction }: IProps) => {
    const appListOption = useSelector(selectAppListOptions)
    const gameIdOption = useSelector(selectGameIdOptions)
    const dispatch = useDispatch<AppDispatch>()
    const [expanded, setExpanded] = useState(true)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    useEffect(() => {
        dispatch(fetchAppListOptions())
        dispatch(fetchGameIdOptions())
    }, [dispatch])

    const formik = useFormik<ITransactionListReqForm>({
        initialValues: {
            app: '',
            processedAt: {
                start: dayjs().subtract(7, 'day').toDate(),
                end: dayjs().toDate(),
            },
            gameId: '',
            currency: [],
            amount: {
                min: null,
                max: null,
            },
            type: '',
            txId: '',
            txIdInPartner: '',
            userId: '',
            playerId: '',
            roundId: '',
        },
        validationSchema,
        onSubmit: (values, formikHelper) => {
            console.log(
                '🚀 ~ file: ListFilter.tsx:88 ~ ListFilter ~ values:',
                values
            )
            if (!values.processedAt.start || !values.processedAt.end) {
                return formikHelper.setFieldError(
                    'processedAt',
                    'processedAt need start & end date'
                )
            }
            if (
                values.processedAt.start &&
                values.processedAt.end &&
                values.processedAt.start > values.processedAt.end
            ) {
                return formikHelper.setFieldError(
                    'processedAt',
                    'start date need smaller than end date'
                )
            }
            const filteredFields: ITransactionListReq = {
                app: [values.app],
                processedAt: convertDatePickerToString(values.processedAt),
            }
            if (values.currency && values.currency.length > 0) {
                filteredFields.currency = values.currency
            }
            if (values?.amount?.max !== null && values?.amount?.min !== null) {
                filteredFields.amount = values.amount
            }
            Object.keys(values).forEach((ky) => {
                if (typeof values[ky] === 'string' && values[ky].length > 0) {
                    filteredFields[ky] = values[ky].split(',')
                }
            })
            setExpanded(false)
            onAction(filteredFields)
        },
    })
    const fields: IField<ITransactionListReqForm>[] = [
        {
            key: 'app',
            type: 'select',
            required: true,
            option: appListOption,
        },
        {
            key: 'gameId',
            type: 'select',
            option: gameIdOption,
        },
        {
            key: 'currency',
            type: 'mul-select',
            option: currencyOptions,
        },
        {
            key: 'amount',
            type: 'rangeNum',
        },
        {
            key: 'processedAt',
            type: 'rangeDate',
            required: true,
        },
        {
            key: 'type',
            type: 'select',
            option: transactionTypeOption,
        },
        {
            key: 'txId',
            type: 'input',
        },
        {
            key: 'txIdInPartner',
            type: 'input',
        },
        {
            key: 'userId',
            type: 'input',
        },
        {
            key: 'playerId',
            type: 'input',
        },
        {
            key: 'roundId',
            type: 'input',
        },
    ]
    return (
        <MainCard
            title="Filter"
            sx={{ mb: 3 }}
            secondary={
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show filter"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            }
        >
            <Collapse in={expanded} timeout="auto">
                <CommonForm<ITransactionListReqForm>
                    fields={fields}
                    formik={formik}
                    title=""
                    gridValue={4}
                />
            </Collapse>
        </MainCard>
    )
}
export default ListFilter
