import axios, { IResData } from 'utils/axios'

export interface ILoginRes {
    Authorization: string
}

export interface IReqParams {
    userName: string
    passwd: string
}

export const loginReq = (params: IReqParams) => {
    return axios.post<ILoginRes, IResData>('/merchant/login', {
        userName: params.userName,
        passwd: params.passwd,
    })
}

// export const userInfoReq = () => {
//     return axios.get<null, IResData<UserInfo>>('/api/user/myinfo')
// }

// export const logoutReq = () => {
//     return axios.post<null, IResData>('/api/user/logout')
// }
