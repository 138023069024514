/**
 * axios setup to use mock service
 */

import axios from 'axios'
import { setSession } from 'contexts/JWTContext'

const getBaseURL = () => {
    if (window.location?.host.indexOf('localhost') > -1) {
        return ''
    }
    if (window.location?.host.indexOf('test') > -1) {
        return ''
    }
    //@ts-ignore
    return window?.$config?.api_host
}

const axiosServices = axios.create({
    baseURL: getBaseURL(),
})

export interface IResData<DataType = Record<string, any>> {
    code: number
    message: string
    data: DataType
    total?: number
}

export interface ICommonListRes<ItemListType> {
    total?: number
    list: ItemListType | null
}

// interceptor for http
axiosServices.interceptors.response.use(
    (res) => {
        if (res.status !== 200 || !res?.data) {
            return Promise.reject(res.statusText || 'Wrong Services')
        }
        if (res?.data?.code === 30005) {
            // token expire
            setSession(null)
            window.location.href = '/login'
        }
        return res.data
    },
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || 'Wrong Services'
        )
)

export default axiosServices
