import { Tooltip, Typography } from '@mui/material'

interface IProps {
    text: string
}

const PopoverText = ({ text }: IProps) => {
    return (
        <Tooltip title={text}>
            <div
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '6rem',
                }}
            >
                <Typography noWrap>{text}</Typography>
            </div>
        </Tooltip>
    )
}
export default PopoverText
