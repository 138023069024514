// routing
import Routes from 'routes'

// project imports
import Locales from 'ui-component/Locales'
import NavigationScroll from 'layout/NavigationScroll'
import Snackbar from 'ui-component/extended/Snackbar'
import ThemeCustomization from 'themes'
import Notistack from 'ui-component/third-party/Notistack'

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext'
import GlobalLoader from 'ui-component/GlobalLoader'

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        <Locales>
            <NavigationScroll>
                <AuthProvider>
                    <>
                        <Notistack>
                            <GlobalLoader />
                            <Routes />
                            <Snackbar />
                        </Notistack>
                    </>
                </AuthProvider>
            </NavigationScroll>
        </Locales>
    </ThemeCustomization>
)

export default App
