import React, { createContext, useEffect, useReducer } from 'react'

// third-party
// import { Chance } from 'chance';
import jwtDecode from 'jwt-decode'

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions'
import accountReducer from 'store/accountReducer'

// project imports
import Loader from 'ui-component/Loader'
import axios from 'utils/axios'

// types
import { InitialLoginContextProps, JWTContextType } from 'types/auth'
import { loginReq } from 'apis/auth'
import { TOKEN_HEADER } from 'config'

// const chance = new Chance();

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
}

interface tokenUser {
    iss: string // user name
    id: number
}
const decodeToken: (token: string) => tokenUser = (serviceToken) => {
    const decoded: tokenUser = jwtDecode(serviceToken)
    return decoded
}

export const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken)
        axios.defaults.headers.common[TOKEN_HEADER] = serviceToken
    } else {
        localStorage.removeItem('serviceToken')
        delete axios.defaults.headers.common[TOKEN_HEADER]
    }
}

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null)

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState)

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken')
                if (serviceToken) {
                    setSession(serviceToken)
                    const tokenInfo = decodeToken(serviceToken)
                    // const response = await userInfoReq()
                    // const user = response.data
                    if (tokenInfo) {
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: {
                                    username: tokenInfo.iss,
                                    userid: tokenInfo.id,
                                },
                            },
                        })
                    }
                } else {
                    dispatch({
                        type: LOGOUT,
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: LOGOUT,
                })
            }
        }

        init()
    }, [])

    const login = async (userName: string, passwd: string) => {
        const res = await loginReq({
            userName,
            passwd,
        })
        if (res.code === 0) {
            if (res?.data?.token) {
                const tokenInfo = decodeToken(res?.data?.token)
                if (tokenInfo) {
                    setSession(res?.data?.token as string)
                    // const response = await userInfoReq()
                    // const user = response.data
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                username: tokenInfo.iss,
                                userid: tokenInfo.id,
                            },
                        },
                    })
                }
            }
        }
        return res
    }

    // const register = async (
    //     email: string,
    //     password: string,
    //     firstName: string,
    //     lastName: string
    // ) => {
    // todo: this flow need to be recode as it not verified
    // const id = chance.bb_pin();
    // const response = await axios.post('/api/account/register', {
    //     id,
    //     email,
    //     password,
    //     firstName,
    //     lastName
    // });
    // let users = response.data;
    // if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
    //     const localUsers = window.localStorage.getItem('users');
    //     users = [
    //         ...JSON.parse(localUsers!),
    //         {
    //             id,
    //             email,
    //             password,
    //             name: `${firstName} ${lastName}`
    //         }
    //     ];
    // }
    // window.localStorage.setItem('users', JSON.stringify(users));
    // }

    const logout = async () => {
        // await logoutReq()
        setSession(null)
        dispatch({ type: LOGOUT })
    }

    // const resetPassword = async (email: string) => {}

    const updateProfile = () => {}

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                // register,
                // resetPassword,
                updateProfile,
            }}
        >
            {children}
        </JWTContext.Provider>
    )
}

export default JWTContext
