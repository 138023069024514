import { Suspense, ElementType } from 'react'

// project imports
import Loader from './Loader'

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

function Loadable<PropType>(Component: ElementType) {
    return (props: PropType) => (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    )
}

export default Loadable
