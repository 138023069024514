import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isShow: false,
}

const loader = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoader(state, action) {
            const { status } = action.payload
            state.isShow = status
        },
    },
})

export default loader.reducer

export const { setLoader } = loader.actions
