import LAYOUT_CONST from 'constant'

// types
import { ConfigProps } from 'types/config'

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/Ibox-material-react/react/default'
export const BASE_PATH = ''

export const DASHBOARD_PATH = '/home'
export const HORIZONTAL_MAX_ITEM = 7
export const TOKEN_HEADER = 'merchant-token'
const config: ConfigProps = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2,
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    container: false,
}

export default config
