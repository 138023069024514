// project imports
import AuthGuard from 'utils/route-guard/AuthGuard'
import MainLayout from 'layout/MainLayout'
import PlayerList from 'views/playerList/List'
import Home from 'views/home'
import AppList from 'views/appList/List'
import RoundList from 'views/roundList/List'
import TransactionList from 'views/transactionList/List'
import GgrBets from 'views/ggrBets/List'
import GgrBetsByMNC from 'views/ggrBetsByMNC/List'

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <Home />,
        },
        {
            path: '/app-list',
            element: <AppList />,
        },
        {
            path: '/player-list',
            element: <PlayerList />,
        },
        {
            path: '/round-list',
            element: <RoundList />,
        },
        {
            path: '/transaction-list',
            element: <TransactionList />,
        },
        {
            path: '/ggr-bets',
            element: <GgrBets />,
        },
        {
            path: '/ggr-bets-by-month-currency',
            element: <GgrBetsByMNC />,
        },
    ],
}

export default MainRoutes
