import React, { ReactElement, ReactNode } from 'react'

// material-ui
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Button,
    TableCellProps,
    TablePagination,
    Typography,
    Box,
} from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage'
// project imports
import MainCard from 'ui-component/cards/MainCard'
// assets

import AddIcon from '@mui/icons-material/Add'
import { FormattedMessage } from 'react-intl'
import { defaultPageSize, pageSizeArr } from './pageConfig'

// ==============================|| TABLE - DATA TABLE ||============================== //
export interface IColsItem<ItemInterface> {
    id: string
    label: ReactNode
    align?: TableCellProps['align']
    cellRender: (row: ItemInterface) => ReactNode
}
export interface IDataTableProps<ItemInterface> {
    data: ItemInterface[]
    cols: IColsItem<ItemInterface>[]
    uniKey: keyof ItemInterface
    page: number
    size: number
    total: number
    onPageChange: (page: number) => void
    onSizeChange: (size: number) => void
    title?: ReactNode
    handleAdd?: () => void
    returnActionComponent?: (row: ItemInterface) => ReactNode
}

export const DataTable: <ItemInterface>(
    props: IDataTableProps<ItemInterface>
) => ReactElement = ({
    data = [],
    cols = [],
    page = 1,
    size = defaultPageSize,
    total,
    onPageChange,
    onSizeChange,
    uniKey,
    title = '',
    handleAdd,
    returnActionComponent,
}) => {
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        onPageChange(newPage + 1)
    }

    const handleChangeRowsPerPage = (
        event:
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | undefined
    ) => {
        onSizeChange(parseInt(event?.target.value!, 10))
        onPageChange(1)
    }
    // Avoid a layout jump when reaching the last page with empty data.
    // const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

    return (
        <MainCard
            content={false}
            title={title}
            // secondary={
            //     <Tooltip title={<FormattedMessage id="add-title" />}>
            //         <Button
            //             variant="contained"
            //             size="small"
            //             color="secondary"
            //             onClick={handleAdd}
            //             endIcon={<AddIcon />}
            //         >
            //             <FormattedMessage id="add" />
            //         </Button>
            //     </Tooltip>
            // }
        >
            {/* table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} stickyHeader>
                    <TableHead>
                        <TableRow>
                            {cols.map((col) => (
                                <TableCell
                                    key={col.id}
                                    align={col.align}
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    {col.label}
                                </TableCell>
                            ))}
                            {returnActionComponent && (
                                <TableCell key="action" align="center">
                                    <FormattedMessage id="action" />
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => {
                            return (
                                <TableRow hover key={row[uniKey] as string}>
                                    {cols.map((col) => {
                                        return (
                                            <TableCell
                                                align={col.align}
                                                key={col.id}
                                            >
                                                {col.cellRender(row)}
                                            </TableCell>
                                        )
                                    })}

                                    {returnActionComponent && (
                                        <TableCell align="center">
                                            {returnActionComponent(row)}
                                        </TableCell>
                                    )}
                                </TableRow>
                            )
                        })}
                        {data.length === 0 && (
                            <TableRow
                                style={{
                                    height: 50,
                                }}
                            >
                                <TableCell align="center" colSpan={cols.length}>
                                    <Box sx={{ py: 5 }}>
                                        <FindInPageIcon fontSize="large" />
                                        <Typography variant="h4">
                                            Empty
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table data */}
            {total > 0 && (
                <TablePagination
                    rowsPerPageOptions={pageSizeArr}
                    component="div"
                    count={total}
                    rowsPerPage={size}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={<FormattedMessage id="row-per-page" />}
                />
            )}
        </MainCard>
    )
}
