import { OptionObj } from 'types'
export enum Currency {
    BTC,
    ETH,
    USDT,
    TRX,
    BNB,
    JPY,
    BRL,
    NGN,
    USD,
    INR,
    PHP,
    TT,
    IDR,
}
export const currencyOptions: OptionObj[] = Object.entries(Currency)
    .filter((e) => !isNaN(e[0] as any))
    .map((e) => ({ label: e[1] as string, value: e[1] }))
