import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'

// maintenance routing
const MaintenanceError = Loadable(
    lazy(() => import('views/auth/maintenance/Error'))
)

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <MaintenanceError />,
        },
    ],
}

export default AuthenticationRoutes
