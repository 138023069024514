// material-ui
import { useEffect, useState } from 'react'
// project imports
import { DataTable, IColsItem } from 'ui-component/table'
import { FormattedMessage } from 'react-intl'

import DetailDrawer from '../../ui-component/detailDrawer/DetailDrawer'
import { useNavigate } from 'react-router-dom'
import { IAppItem, getAppList } from 'apis/app'
import useSnackbar from 'hooks/useSnackbar'
import { defaultPageSize } from 'ui-component/table/pageConfig'

const headCells: IColsItem<IAppItem>[] = [
    // {
    //     id: 'appKey',
    //     label: <FormattedMessage id="app-key" />,
    //     align: 'left',
    //     cellRender: (row) => {
    //         return row.app_key
    //     },
    // },
    {
        id: 'appName',
        label: <FormattedMessage id="app-name" />,
        align: 'left',
        cellRender: (row) => {
            return row.app
        },
    },
    // {
    //     id: 'appSecret',
    //     label: <FormattedMessage id="app-secret" />,
    //     align: 'left',
    //     cellRender: (row) => {
    //         return row.app_secret
    //     },
    // },
    // {
    //     id: 'partnerId',
    //     label: <FormattedMessage id="partner-id" />,
    //     align: 'left',
    //     cellRender: (row) => {
    //         return row.partner_id
    //     },
    // },
    {
        id: 'partnerName',
        label: <FormattedMessage id="partner-name" />,
        align: 'left',
        cellRender: (row) => {
            return row.partner
        },
    },
    // {
    //     id: 'permission',
    //     label: <FormattedMessage id="permission" />,
    //     align: 'left',
    //     cellRender: (row) => {
    //         return row.permission
    //     },
    // },
    {
        id: 'caller',
        label: <FormattedMessage id="caller" />,
        align: 'left',
        cellRender: (row) => {
            return row.caller
        },
    },
]
const AppList = () => {
    const [data, setData] = useState<IAppItem[]>([])
    const [openDetailDrawer, setOpenDetailDrawer] = useState<boolean>(false)
    const [focusItem, setFocusItem] = useState<IAppItem | null>(null)
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(defaultPageSize)
    const [total, setTotal] = useState(0)
    const { showError } = useSnackbar()
    const navigate = useNavigate()

    useEffect(() => {
        fetchListData()
    }, [page, size])

    const fetchListData = async () => {
        const res = await getAppList({
            page,
            limit: size,
        })
        if (res.code !== 0) {
            showError(res.message)
        }
        if (res.data.list) {
            setData(res.data.list)
        }
        if (res.data.total) {
            setTotal(res.data.total)
        }
    }

    const handleDetail = (row: IAppItem) => {
        setFocusItem(row)
        setOpenDetailDrawer(true)
    }

    const handleFilterAction = async (values: IAppItem) => {
        setPage(1)
        setSize(defaultPageSize)
        // const fixFilterValues: IMerchantInfoParams = Object.fromEntries(
        //     Object.entries(values).filter(([_, v]) => v !== '')
        // )

        // const res = await getMerchantList({
        //     page,
        //     size,
        //     ...fixFilterValues,
        // })
        // setData(res.data.results)
        // setTotal(res.data.count)
    }

    // const returnActionComponent = (row: IAppItem) => {
    //     return (
    //         <>
    //             <Tooltip title={<FormattedMessage id="detail" />}>
    //                 <IconButton
    //                     onClick={() => {
    //                         handleDetail(row)
    //                     }}
    //                 >
    //                     <InfoIcon />
    //                 </IconButton>
    //             </Tooltip>
    //         </>
    //     )
    // }
    return (
        <>
            {/* <ListFilter onAction={handleFilterAction} /> */}
            <DataTable<IAppItem>
                data={data}
                cols={headCells}
                uniKey="partner_id"
                title={<FormattedMessage id="app-list" />}
                // returnActionComponent={returnActionComponent}
                page={page}
                size={size}
                total={total}
                onPageChange={(page) => {
                    setPage(page)
                }}
                onSizeChange={(size) => {
                    setSize(size)
                }}
            />
            {/* {openModal && (
                <AlertDelete
                    title={focusItem && focusItem.name}
                    open={openModal}
                    handleClose={handleModalClose}
                    deleteAction={deleteItem}
                />
            )} */}
            <DetailDrawer<IAppItem>
                open={openDetailDrawer}
                handleClose={() => {
                    setOpenDetailDrawer(false)
                }}
                item={focusItem}
            />
        </>
    )
}

export default AppList
