import { useEffect, useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material'

// third-party
import { FormattedMessage } from 'react-intl'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import Transitions from 'ui-component/extended/Transitions'
import useAuth from 'hooks/useAuth'
import User1 from 'assets/images/users/user-round.svg'

// assets
import { IconLogout, IconSettings } from '@tabler/icons'
import useConfig from 'hooks/useConfig'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme()
    const { borderRadius } = useConfig()

    const { logout, user } = useAuth()
    const [open, setOpen] = useState(false)
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null)
    const handleLogout = async () => {
        try {
            await logout()
        } catch (err) {
            console.error(err)
        }
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }
    const handleClose = (
        event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
    ) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor:
                        theme.palette.mode === 'dark'
                            ? theme.palette.dark.main
                            : theme.palette.primary.light,
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? theme.palette.dark.main
                            : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light,
                        },
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0,
                    },
                }}
                icon={
                    <Avatar
                        src={User1}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                        alt="user-images"
                    />
                }
                label={
                    <IconSettings
                        stroke={1.5}
                        size="1.5rem"
                        color={theme.palette.primary.main}
                    />
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
                aria-label="user-account"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14],
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard
                                        border={false}
                                        elevation={16}
                                        content={false}
                                        boxShadow
                                        shadow={theme.shadows[16]}
                                    >
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack>
                                                <Stack
                                                    direction="row"
                                                    spacing={0.5}
                                                    alignItems="center"
                                                >
                                                    <Typography variant="h4">
                                                        Good Morning,
                                                    </Typography>
                                                    <Typography
                                                        component="span"
                                                        variant="h4"
                                                        sx={{ fontWeight: 400 }}
                                                    >
                                                        {user?.username}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle2">
                                                    Admin
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        <Box sx={{ p: 2, pt: 0 }}>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor:
                                                        theme.palette.background
                                                            .paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down(
                                                        'md'
                                                    )]: {
                                                        minWidth: '100%',
                                                    },
                                                    '& .MuiListItemButton-root':
                                                        {
                                                            mt: 0.5,
                                                        },
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${borderRadius}px`,
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings
                                                            stroke={1.5}
                                                            size="1.3rem"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                <FormattedMessage id="account-settings" />
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${borderRadius}px`,
                                                    }}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout
                                                            stroke={1.5}
                                                            size="1.3rem"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                <FormattedMessage id="logout" />
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    )
}

export default ProfileSection
