import Typography from '@mui/material/Typography'

const Logo = () => {
    return (
        <Typography variant="h2" gutterBottom>
            Back Office
        </Typography>
    )
}

export default Logo
