import { RangeDate, RangeDateStr } from 'types'
import dayjs from 'dayjs'

export const fixDateString = (str: string) => new Date(str).toISOString()

export const fixUnknownDataToArr = (data: unknown) => {
    if (!Array.isArray(data)) {
        return []
    }
    return data
}

export const convertStartDate = (date: Date | null) =>
    date ? dayjs(date).format('YYYY-MM-DD') + ' 00:00:00' : ''
export const convertEndDate = (date?: Date | null) =>
    date ? dayjs(date).format('YYYY-MM-DD') + ' 23:59:59' : ''
export const convertDatePickerToString = (dateObj: RangeDate): RangeDateStr => {
    return {
        start: convertStartDate(dateObj.start),
        end: convertEndDate(dateObj.end),
    }
}
