// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl'

// assets
import {
    IconHome,
    IconWallet,
    IconBuildingStore,
    IconBuildingStadium,
    IconGoGame,
    IconUsers,
} from '@tabler/icons'

// type
import { NavItemType } from 'types'

// ==============================|| MENU ITEMS - main menu ||============================== //

const mainMenu: NavItemType[] = [
    {
        id: 'home',
        title: <FormattedMessage id="home" />,
        icon: IconHome,
        type: 'group',
        url: '/home',
    },
    {
        id: 'operational-reports',
        title: <FormattedMessage id="operational-reports" />,
        type: 'group',
        children: [
            {
                id: 'player-list',
                title: <FormattedMessage id="player-list" />,
                type: 'item',
                icon: IconUsers,
                url: '/player-list',
            },
            {
                id: 'round-list',
                title: <FormattedMessage id="round-list" />,
                type: 'item',
                icon: IconGoGame,
                url: '/round-list',
            },
            {
                id: 'transaction-list',
                title: <FormattedMessage id="transaction-list" />,
                type: 'item',
                icon: IconWallet,
                url: '/transaction-list',
            },
        ],
    },
    {
        id: 'financial-reports',
        title: <FormattedMessage id="financial-reports" />,
        icon: IconWallet,
        type: 'group',
        children: [
            {
                id: 'ggr-bets',
                title: <FormattedMessage id="ggr-bets" />,
                type: 'item',
                icon: IconBuildingStore,
                url: '/ggr-bets',
            },
            {
                id: 'ggr-bets-by-month-currency',
                title: <FormattedMessage id="ggr-bets-by-month-currency" />,
                type: 'item',
                icon: IconBuildingStadium,
                url: '/ggr-bets-by-month-currency',
            },
        ],
    },
]

export default mainMenu
