// project import
import mainMenu from './mainMenu'
// import user from './user'

// types
import { NavItemType } from 'types'

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [...mainMenu],
}

export default menuItems
