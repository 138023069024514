// material-ui
import { useEffect, useState } from 'react'
// project imports
import { DataTable, IColsItem } from 'ui-component/table'
import { FormattedMessage } from 'react-intl'
import { fixDateString } from 'utils/utils'
import ListFilter from './ListFilter'
import TabNavi from 'views/common/TabNavi'
import { operationTabs } from 'views/common/operationTabs'
import { IRoundItem, IRoundListReq, getRoundList } from 'apis/round'
import useSnackbar from 'hooks/useSnackbar'
import { defaultPageSize } from 'ui-component/table/pageConfig'
import { useDispatch } from 'store'
import { setLoader } from 'store/slices/loader'

const headCells: IColsItem<IRoundItem>[] = [
    {
        id: 'app',
        label: <FormattedMessage id="app-name" />,
        align: 'left',
        cellRender: (row) => {
            return row.app
        },
    },
    {
        id: 'userId',
        label: <FormattedMessage id="user-id" />,
        cellRender: (row) => {
            return row.user_id
        },
    },
    {
        id: 'playerId',
        label: <FormattedMessage id="player-id" />,
        align: 'left',
        cellRender: (row) => {
            return `${row.player_id}`
        },
    },
    {
        id: 'gameId',
        label: <FormattedMessage id="game-id" />,
        cellRender: (row) => {
            return row.game_id
        },
    },
    {
        id: 'roundId',
        label: <FormattedMessage id="round-id" />,
        cellRender: (row) => {
            return row.round_id
        },
    },
    {
        id: 'currency',
        label: <FormattedMessage id="currency" />,
        cellRender: (row) => {
            return row.currency
        },
    },
    {
        id: 'winAmount',
        label: <FormattedMessage id="win-amount" />,
        cellRender: (row) => {
            return row.win_amount
        },
    },
    {
        id: 'betAmount',
        label: <FormattedMessage id="bet-amount" />,
        cellRender: (row) => {
            return row.bet_amount
        },
    },
    {
        id: 'startedAt',
        label: <FormattedMessage id="started-at" />,
        cellRender: (row) => {
            return row.started_at && fixDateString(row.started_at)
        },
    },
    {
        id: 'finishedAt',
        label: <FormattedMessage id="finished-at" />,
        cellRender: (row) => {
            return row.finished_at && fixDateString(row.finished_at)
        },
    },
    {
        id: 'detail',
        label: <FormattedMessage id="detail" />,
        cellRender: (row) => {
            return row.detail
        },
    },
]
const RoundList = () => {
    const [data, setData] = useState<IRoundItem[]>([])
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(defaultPageSize)
    const [total, setTotal] = useState(0)
    const { showError } = useSnackbar()
    const dispatch = useDispatch()
    const [currentQuery, setCurrentQuery] = useState<IRoundListReq>({
        app: [],
        startedAt: {
            start: '',
            end: '',
        },
    })

    useEffect(() => {
        const fetchListData = async () => {
            if (currentQuery.app.length === 0) {
                return
            }
            dispatch(
                setLoader({
                    status: true,
                })
            )
            const res = await getRoundList({
                page,
                limit: size,
                ...currentQuery,
            })
            if (res.code !== 0) {
                showError(res.message)
            }
            setData(res.data.list || [])

            if (res.data.total) {
                setTotal(res.data.total)
            }
            dispatch(
                setLoader({
                    status: false,
                })
            )
        }
        fetchListData()
    }, [page, size, currentQuery, showError])

    const handleFilterAction = async (values: IRoundListReq) => {
        setPage(1)
        setSize(defaultPageSize)
        setCurrentQuery(values)
    }

    return (
        <>
            <TabNavi tabs={operationTabs} />
            <ListFilter onAction={handleFilterAction} />
            <DataTable<IRoundItem>
                data={data}
                cols={headCells}
                uniKey="round_id"
                title={<FormattedMessage id="round-list" />}
                page={page}
                size={size}
                total={total}
                onPageChange={(page) => {
                    setPage(page)
                }}
                onSizeChange={(size) => {
                    setSize(size)
                }}
            />
        </>
    )
}

export default RoundList
