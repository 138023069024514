// third party
import { number, object, string, ref } from 'yup'
import { useFormik } from 'formik'

// project imports
import CommonForm, { IField } from 'ui-component/form'
import MainCard from 'ui-component/cards/MainCard'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IRoundListReq, IRoundListReqForm } from 'apis/round'
import { currencyOptions } from 'utils/currency'
import { convertDatePickerToString } from 'utils/utils'
import { useEffect, useState } from 'react'
import {
    fetchAppListOptions,
    fetchGameIdOptions,
    selectAppListOptions,
    selectGameIdOptions,
} from 'store/slices/asyncOptions'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'store'
import dayjs from 'dayjs'
import { ExpandMore } from 'ui-component/ExpandMore'
import { Collapse } from '@mui/material'

interface IProps {
    onAction: (values: IRoundListReq) => void
}

const validationSchema = object({
    app: string().trim().required(),
    totalBetAmount: object({
        min: number().lessThan(ref('max')).nullable(),
        max: number().moreThan(ref('min')).nullable(),
    }),
    totalWinAmount: object({
        min: number().lessThan(ref('max')).nullable(),
        max: number().moreThan(ref('min')).nullable(),
    }),
})

// ==============================|| KANBAN BACKLOGS - ADD STORY ||============================== //

const ListFilter = ({ onAction }: IProps) => {
    const appListOption = useSelector(selectAppListOptions)
    const gameIdOption = useSelector(selectGameIdOptions)
    const dispatch = useDispatch<AppDispatch>()
    const [expanded, setExpanded] = useState(true)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    useEffect(() => {
        dispatch(fetchAppListOptions())
        dispatch(fetchGameIdOptions())
    }, [dispatch])

    const formik = useFormik<IRoundListReqForm>({
        initialValues: {
            app: '',
            gameId: '',
            currency: [],
            totalBetAmount: {
                min: null,
                max: null,
            },
            totalWinAmount: {
                min: null,
                max: null,
            },
            startedAt: {
                start: dayjs().subtract(14, 'day').toDate(),
                end: dayjs().subtract(7, 'day').toDate(),
            },
            finishedAt: {
                start: dayjs().subtract(7, 'day').toDate(),
                end: dayjs().toDate(),
            },
            userId: '',
            playerId: '',
            roundId: '',
        },
        validationSchema,
        onSubmit: (values, formikHelper) => {
            const hasFinishedAt = Boolean(
                values?.finishedAt?.start && values?.finishedAt?.end
            )
            if (!values.startedAt.start || !values.startedAt.end) {
                return formikHelper.setFieldError(
                    'startedAt',
                    'startedAt need start & end date'
                )
            }
            if (values.startedAt.start > values.startedAt.end) {
                return formikHelper.setFieldError(
                    'startedAt',
                    'start date need smaller than end date'
                )
            }

            if (
                hasFinishedAt &&
                //@ts-ignore
                values.finishedAt.start > values.finishedAt.end
            ) {
                return formikHelper.setFieldError(
                    'finishedAt',
                    'start date need smaller than end date'
                )
            }
            const filteredFields: IRoundListReq = {
                app: [values.app],
                startedAt: convertDatePickerToString(values.startedAt),
            }
            if (values.finishedAt) {
                filteredFields.finishedAt = convertDatePickerToString(
                    values.finishedAt
                )
            }
            if (
                values?.totalBetAmount?.max !== null &&
                values?.totalBetAmount?.min !== null
            ) {
                filteredFields.totalBetAmount = values.totalBetAmount
            }
            if (
                values?.totalWinAmount?.max !== null &&
                values?.totalWinAmount?.min !== null
            ) {
                filteredFields.totalWinAmount = values.totalWinAmount
            }
            if (values.currency && values.currency.length > 0) {
                filteredFields.currency = values.currency
            }
            Object.keys(values).forEach((ky) => {
                if (typeof values[ky] === 'string' && values[ky].length > 0) {
                    filteredFields[ky] = values[ky].split(',')
                }
            })
            // console.log(
            //     '🚀 ~ file: ListFilter.tsx:135 ~ ListFilter ~ filteredFields:',
            //     filteredFields
            // )
            setExpanded(false)
            onAction(filteredFields)
        },
    })
    const fields: IField<IRoundListReqForm>[] = [
        {
            key: 'app',
            type: 'select',
            required: true,
            option: appListOption,
        },
        {
            key: 'gameId',
            type: 'select',
            option: gameIdOption,
        },
        {
            key: 'currency',
            type: 'mul-select',
            option: currencyOptions,
        },
        {
            key: 'totalBetAmount',
            type: 'rangeNum',
        },
        {
            key: 'totalWinAmount',
            type: 'rangeNum',
        },
        {
            key: 'startedAt',
            required: true,
            type: 'rangeDate',
        },
        {
            key: 'finishedAt',
            required: true,
            type: 'rangeDate',
        },
        {
            key: 'userId',
            type: 'input',
        },
        {
            key: 'playerId',
            type: 'input',
        },
        {
            key: 'roundId',
            type: 'input',
        },
    ]
    return (
        <MainCard
            title="Filter"
            sx={{ mb: 3 }}
            secondary={
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show filter"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            }
        >
            <Collapse in={expanded} timeout="auto">
                <CommonForm<IRoundListReqForm>
                    fields={fields}
                    formik={formik}
                    title=""
                    gridValue={4}
                />
            </Collapse>
        </MainCard>
    )
}
export default ListFilter
