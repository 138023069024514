import axios, { ICommonListRes, IResData } from 'utils/axios'
import { Country } from 'utils/country'
import { Currency } from 'utils/currency'

export interface IPlayerListReq {
    page?: number
    limit?: number
    partnerId?: string
    userId?: string
    playerId?: string
    currency?: keyof typeof Currency | ''
    email?: string
    city?: string
    nickName?: string
    firstName?: string
    lastName?: string
    country?: keyof typeof Country | ''
    gender?: 'm' | 'f' | ''
}

export interface IPlayerItem {
    partner?: string
    partner_id?: string
    app?: string
    player_id?: string
    user_id?: string
    email?: string
    firstname?: string
    lastname?: string
    nickname?: string
    city?: string
    date_of_birth?: string
    registered_at?: string
    gender?: string
    country?: string
    avatar?: string
    currency?: string
}

type PlayerRes = IResData<ICommonListRes<IPlayerItem[]>>
export const getPlayerList = (params: IPlayerListReq): Promise<PlayerRes> => {
    return axios.post<IPlayerListReq, PlayerRes>(
        '/merchant/get-player-info-list',
        params
    )
}
