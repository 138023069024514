import { RangeDate, RangeDateStr, RangeNum } from 'types'
import axios, { ICommonListRes, IResData } from 'utils/axios'
import { Currency } from 'utils/currency'

export interface ITransactionListReqForm {
    page?: number
    limit?: number
    app: string
    processedAt: RangeDate
    gameId?: string
    currency?: Array<keyof typeof Currency>
    amount?: RangeNum
    type?: string
    txId?: string
    txIdInPartner?: string
    userId?: string
    playerId?: string
    roundId?: string
}
export interface ITransactionListReq {
    page?: number
    limit?: number
    app: string[]
    processedAt: RangeDateStr
    gameId?: string[]
    currency?: Array<keyof typeof Currency>
    amount?: RangeNum
    type?: string[]
    txId?: string[]
    txIdInPartner?: string[]
    userId?: string[]
    playerId?: string[]
    roundId?: string[]
}

export interface ITransactionItem {
    tx_id?: string
    tx_id_in_partner?: string
    partner_id?: string
    user_id?: string
    player_id?: string
    round_id?: string
    game_id?: string
    currency?: string
    amount?: string
    type?: string
    processed_at?: string
}

type TransRes = IResData<ICommonListRes<ITransactionItem[]>>
export const getTransactionList = (
    params: ITransactionListReq
): Promise<TransRes> => {
    return axios.post<ITransactionListReq, TransRes>(
        '/merchant/get-transaction-list',
        params
    )
}
