// material-ui
import { useEffect, useState } from 'react'
// project imports
import { DataTable, IColsItem } from 'ui-component/table'
import { FormattedMessage } from 'react-intl'
import ListFilter from './ListFilter'
import { IPlayerItem, IPlayerListReq, getPlayerList } from 'apis/player'
import TabNavi from 'views/common/TabNavi'
import { operationTabs } from 'views/common/operationTabs'
import useSnackbar from 'hooks/useSnackbar'
import { defaultPageSize } from 'ui-component/table/pageConfig'
import { useDispatch } from 'store'
import { setLoader } from 'store/slices/loader'

const headCells: IColsItem<IPlayerItem>[] = [
    {
        id: 'userId',
        label: <FormattedMessage id="user-id" />,
        align: 'left',
        cellRender: (row) => {
            return row.user_id
        },
    },
    {
        id: 'playerId',
        label: <FormattedMessage id="player-id" />,
        align: 'left',
        cellRender: (row) => {
            return row.player_id
        },
    },
    {
        id: 'name',
        label: <FormattedMessage id="name" />,
        align: 'left',
        cellRender: (row) => {
            return `${row.firstname}-${row.lastname} / ${row.nickname}`
        },
    },
    {
        id: 'email',
        label: <FormattedMessage id="email" />,
        align: 'left',
        cellRender: (row) => {
            return row.email
        },
    },

    {
        id: 'appName',
        label: <FormattedMessage id="app-name" />,
        align: 'left',
        cellRender: (row) => {
            return row.app
        },
    },
    // {
    //     id: 'appSecret',
    //     label: <FormattedMessage id="app-secret" />,
    //     align: 'left',
    //     cellRender: (row) => {
    //         return row.app_secret
    //     },
    // },
    // {
    //     id: 'partnerId',
    //     label: <FormattedMessage id="partner-id" />,
    //     align: 'left',
    //     cellRender: (row) => {
    //         return row.partner_id
    //     },
    // },
    {
        id: 'partnerName',
        label: <FormattedMessage id="partner-name" />,
        align: 'left',
        cellRender: (row) => {
            return row.partner
        },
    },
    // {
    //     id: 'permission',
    //     label: <FormattedMessage id="permission" />,
    //     align: 'left',
    //     cellRender: (row) => {
    //         return row.permission
    //     },
    // },
]
const AppList = () => {
    const [data, setData] = useState<IPlayerItem[]>([])
    const { showError } = useSnackbar()
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(defaultPageSize)
    const [total, setTotal] = useState(0)
    const [currentQuery, setCurrentQuery] = useState<IPlayerListReq>({})
    const [filterErrorMsg, setFilterErrorMsg] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchListData = async () => {
            dispatch(
                setLoader({
                    status: true,
                })
            )
            const res = await getPlayerList({
                page,
                limit: size,
                ...currentQuery,
            })
            if (res.code !== 0) {
                showError(res.message)
            }
            setData(res.data.list || [])

            if (res.data.total) {
                setTotal(res.data.total)
            }
            dispatch(
                setLoader({
                    status: false,
                })
            )
        }
        fetchListData()
    }, [page, size, currentQuery, showError, dispatch])

    const handleFilterAction = async (reqFields: IPlayerListReq) => {
        if (Object.values(reqFields).join('').length === 0) {
            return setFilterErrorMsg('Please set at least one field')
        } else {
            setFilterErrorMsg('')
        }
        setPage(1)
        setSize(defaultPageSize)
        setCurrentQuery(reqFields)
    }
    return (
        <>
            <TabNavi tabs={operationTabs} />
            <ListFilter
                onAction={handleFilterAction}
                errorMsg={filterErrorMsg}
            />
            <DataTable<IPlayerItem>
                data={data}
                cols={headCells}
                uniKey="player_id"
                title={<FormattedMessage id="player-list" />}
                // returnActionComponent={returnActionComponent}
                page={page}
                size={size}
                total={total}
                onPageChange={(page) => {
                    setPage(page)
                }}
                onSizeChange={(size) => {
                    setSize(size)
                }}
            />
            {/* {openModal && (
                <AlertDelete
                    title={focusItem && focusItem.name}
                    open={openModal}
                    handleClose={handleModalClose}
                    deleteAction={deleteItem}
                />
            )} */}
            {/* <DetailDrawer<IPlayerItem>
                open={openDetailDrawer}
                handleClose={() => {
                    setOpenDetailDrawer(false)
                }}
                item={focusItem}
            /> */}
        </>
    )
}

export default AppList
