import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openSnackbar } from 'store/slices/snackbar'

// ==============================|| ELEMENT REFERENCE HOOKS ||============================== //

const useSnackbar = () => {
    const dispatch = useDispatch()

    const showError = useCallback(
        (msg: string) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: msg,
                    variant: 'alert',
                    alert: {
                        variant: 'filled',
                        color: 'error',
                    },
                })
            )
        },
        [dispatch]
    )
    return {
        showError,
    }
}

export default useSnackbar
