// third party
import { object, string } from 'yup'
import { useFormik } from 'formik'

// project imports
import CommonForm, { IField } from 'ui-component/form'
import MainCard from 'ui-component/cards/MainCard'
import { IPlayerListReq } from 'apis/player'
import { currencyOptions } from 'utils/currency'
import { countryOptions } from 'utils/country'
import { useState } from 'react'
import { Collapse } from '@mui/material'
import { ExpandMore } from 'ui-component/ExpandMore'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
interface IProps {
    onAction: (values: IPlayerListReq) => void
    errorMsg?: string
}

const validationSchema = object({
    email: string().email(),
})

// ==============================|| KANBAN BACKLOGS - ADD STORY ||============================== //

const ListFilter = ({ onAction, errorMsg }: IProps) => {
    const [expanded, setExpanded] = useState(true)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    const formik = useFormik<IPlayerListReq>({
        initialValues: {
            userId: '',
            playerId: '',
            currency: '',
            city: '',
            country: '',
            gender: '',
            firstName: '',
            lastName: '',
            nickName: '',
            email: '',
        },
        validationSchema,
        onSubmit: (values) => {
            const filteredFields: IPlayerListReq = {}
            Object.keys(values).forEach((ky) => {
                if (typeof values[ky] === 'string' && values[ky].length > 0) {
                    filteredFields[ky] = values[ky]
                }
            })
            setExpanded(false)
            onAction(filteredFields)
        },
    })
    const fields: IField<IPlayerListReq>[] = [
        {
            key: 'userId',
            type: 'input',
        },
        {
            key: 'playerId',
            type: 'input',
        },
        {
            key: 'currency',
            type: 'select',
            option: currencyOptions,
        },
        {
            key: 'city',
            type: 'input',
        },
        {
            key: 'country',
            type: 'select',
            option: countryOptions,
        },
        {
            key: 'email',
            type: 'input',
        },
        {
            key: 'gender',
            type: 'select',
            option: [
                {
                    label: 'female',
                    value: 'f',
                },
                {
                    label: 'male',
                    value: 'm',
                },
            ],
        },
        {
            key: 'firstName',
            type: 'input',
        },
        {
            key: 'lastName',
            type: 'input',
        },
        {
            key: 'nickName',
            type: 'input',
        },
    ]
    return (
        <MainCard
            title="Filter"
            sx={{ mb: 3 }}
            secondary={
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show filter"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            }
        >
            <Collapse in={expanded} timeout="auto">
                <CommonForm<IPlayerListReq>
                    fields={fields}
                    formik={formik}
                    title=""
                    submitErrorMsg={errorMsg}
                    gridValue={4}
                />
            </Collapse>
        </MainCard>
    )
}
export default ListFilter
