import { ITab } from './TabNavi'

export const financialTabs: ITab[] = [
    {
        label: 'ggr-bets',
        link: '/ggr-bets',
    },
    {
        label: 'ggr-bets-by-month-currency',
        link: '/ggr-bets-by-month-currency',
    },
]
