// material-ui
import { useEffect, useState } from 'react'
// project imports
import { FormattedMessage } from 'react-intl'
import ListFilter from './ListFilter'
import TabNavi from 'views/common/TabNavi'
import useSnackbar from 'hooks/useSnackbar'
import { financialTabs } from 'views/common/financialTabs'
import { IGGRBetsItem, IGetGGRBetsReq, getGGRBets } from 'apis/ggr'
import { GGRTable, IColsItem } from 'ui-component/table/GGRTable'
import { defaultPageSize } from 'ui-component/table/pageConfig'
import { useDispatch } from 'store'
import { setLoader } from 'store/slices/loader'

export const ggrItemHeadCells: IColsItem[] = [
    {
        id: 'mainCol',
        label: <FormattedMessage id="GGR-Main-key" />,
        align: 'left',
        cellRender: (row) => {
            return `${row.key}: ${row.value}`
        },
    },
    {
        id: 'ggr',
        label: <FormattedMessage id="ggr" />,
        align: 'left',
        cellRender: (row) => {
            return row.GGR
        },
    },
    {
        id: 'wins',
        label: <FormattedMessage id="wins" />,
        cellRender: (row) => {
            return row.Wins
        },
    },
    {
        id: 'bets',
        label: <FormattedMessage id="bets" />,
        cellRender: (row) => {
            return row.Bets
        },
    },
    {
        id: 'betCount',
        label: <FormattedMessage id="bet-count" />,
        cellRender: (row) => {
            return row.BetCount
        },
    },
    {
        id: 'winCount',
        label: <FormattedMessage id="win-count" />,
        cellRender: (row) => {
            return row.WinCount
        },
    },
    {
        id: 'avgBet',
        label: <FormattedMessage id="avg-bet" />,
        cellRender: (row) => {
            return row.AvgBet
        },
    },
    {
        id: 'rtp',
        label: <FormattedMessage id="rtp" />,
        cellRender: (row) => {
            return row.RTP
        },
    },
]
const GgrBets = () => {
    const [data, setData] = useState<IGGRBetsItem[]>([])
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(defaultPageSize)
    const [total, setTotal] = useState(0)
    const { showError } = useSnackbar()
    const dispatch = useDispatch()
    const [currentQuery, setCurrentQuery] = useState<IGetGGRBetsReq>({
        app: [],
        from: '',
        to: '',
        groupBy: 'NoGroup',
        period: 'day',
    })

    useEffect(() => {
        const fetchListData = async () => {
            if (!currentQuery.from || !currentQuery.to) {
                return
            }
            dispatch(
                setLoader({
                    status: true,
                })
            )
            const res = await getGGRBets({
                page,
                limit: size,
                ...currentQuery,
            })
            if (res.code !== 0) {
                showError(res.message)
            }
            setData(res.data.list || [])

            if (res.data.total) {
                setTotal(res.data.total)
            }
            dispatch(
                setLoader({
                    status: false,
                })
            )
        }
        fetchListData()
    }, [page, size, currentQuery, showError])

    const handleFilterAction = async (values: IGetGGRBetsReq) => {
        setPage(1)
        setSize(defaultPageSize)
        setCurrentQuery(values)
    }

    return (
        <>
            <TabNavi tabs={financialTabs} />
            <ListFilter onAction={handleFilterAction} />
            <GGRTable
                data={data}
                cols={ggrItemHeadCells}
                uniKey="GGR"
                title={<FormattedMessage id="ggr-bets" />}
                page={page}
                size={size}
                total={total}
                onPageChange={(page) => {
                    setPage(page)
                }}
                onSizeChange={(size) => {
                    setSize(size)
                }}
            />
        </>
    )
}

export default GgrBets
