import React, { ReactNode } from 'react'

// material-ui
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableCellProps,
    TablePagination,
    Typography,
    Box,
    IconButton,
} from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage'
// project imports
import MainCard from 'ui-component/cards/MainCard'
// assets

import { FormattedMessage } from 'react-intl'
import { IGGRBetsItem } from 'apis/ggr'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { defaultPageSize, pageSizeArr } from './pageConfig'

// ==============================|| TABLE - DATA TABLE ||============================== //
type ItemInterface = IGGRBetsItem
export interface IColsItem {
    id: string
    label: ReactNode
    align?: TableCellProps['align']
    cellRender: (row: ItemInterface) => ReactNode
}
export interface IDataTableProps {
    data: ItemInterface[]
    cols: IColsItem[]
    uniKey: keyof ItemInterface
    page: number
    size: number
    total: number
    onPageChange: (page: number) => void
    onSizeChange: (size: number) => void
    title?: ReactNode
    handleAdd?: () => void
    returnActionComponent?: (row: ItemInterface) => ReactNode
}

const RowComponent = ({
    rowData: row,
    cols,
    lv,
}: {
    rowData: IGGRBetsItem
    cols: IColsItem[]
    lv: number
}) => {
    const [open, setOpen] = React.useState(false)
    return (
        <>
            <TableRow hover key={row['GGR']}>
                <TableCell>
                    {row.list && (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                            sx={{
                                ml: lv * 2,
                            }}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    )}
                </TableCell>
                {cols.map((col, idx) => {
                    const pl = idx === 0 ? lv * 2 : 0
                    return (
                        <TableCell align={col.align} key={col.id} sx={{ pl }}>
                            {col.cellRender(row)}
                        </TableCell>
                    )
                })}
            </TableRow>
            {open &&
                row.list &&
                row.list.map((rw) => {
                    return (
                        <RowComponent
                            rowData={rw}
                            cols={cols}
                            key={rw.GGR}
                            lv={lv + 1}
                        />
                    )
                })}
        </>
    )
}

export const GGRTable = ({
    data = [],
    cols = [],
    page = 1,
    size = defaultPageSize,
    total,
    onPageChange,
    onSizeChange,
    title = '',
}: IDataTableProps) => {
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        onPageChange(newPage + 1)
    }

    const handleChangeRowsPerPage = (
        event:
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | undefined
    ) => {
        onSizeChange(parseInt(event?.target.value!, 10))
        onPageChange(1)
    }

    return (
        <MainCard content={false} title={title}>
            {/* table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell key={'blank'}></TableCell>
                            {cols.map((col) => (
                                <TableCell
                                    key={col.id}
                                    align={col.align}
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    {col.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => {
                            return (
                                <RowComponent
                                    rowData={row}
                                    cols={cols}
                                    key={row.GGR}
                                    lv={0}
                                />
                            )
                        })}
                        {data.length === 0 && (
                            <TableRow
                                style={{
                                    height: 50,
                                }}
                            >
                                <TableCell align="center" colSpan={cols.length}>
                                    <Box sx={{ py: 5 }}>
                                        <FindInPageIcon fontSize="large" />
                                        <Typography variant="h4">
                                            Empty
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table data */}
            {total > 0 && (
                <TablePagination
                    rowsPerPageOptions={pageSizeArr}
                    component="div"
                    count={total}
                    rowsPerPage={size}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={<FormattedMessage id="row-per-page" />}
                />
            )}
        </MainCard>
    )
}
