// material-ui
import { useEffect, useState } from 'react'
// project imports
import { DataTable, IColsItem } from 'ui-component/table'
import { FormattedMessage } from 'react-intl'
import { fixDateString } from 'utils/utils'
import ListFilter from './ListFilter'
import TabNavi from 'views/common/TabNavi'
import { operationTabs } from 'views/common/operationTabs'
import {
    ITransactionItem,
    ITransactionListReq,
    getTransactionList,
} from 'apis/transaction'
import useSnackbar from 'hooks/useSnackbar'
import PopoverText from 'views/common/PopoverText'
import { defaultPageSize } from 'ui-component/table/pageConfig'
import { useDispatch } from 'store'
import { setLoader } from 'store/slices/loader'

const headCells: IColsItem<ITransactionItem>[] = [
    {
        id: 'txId',
        label: <FormattedMessage id="tx-id" />,
        align: 'left',
        cellRender: (row) => {
            return <PopoverText text={row.tx_id || ''} />
        },
    },
    {
        id: 'txIdInPartner',
        label: <FormattedMessage id="tx-id-in-partner" />,
        align: 'left',
        cellRender: (row) => {
            return <PopoverText text={row.tx_id_in_partner || ''} />
        },
    },
    {
        id: 'roundId',
        label: <FormattedMessage id="round-id" />,
        cellRender: (row) => {
            return <PopoverText text={row.round_id || ''} />
        },
    },
    {
        id: 'gameId',
        label: <FormattedMessage id="game-id" />,
        cellRender: (row) => {
            return row.game_id
        },
    },
    {
        id: 'userId',
        label: <FormattedMessage id="user-id" />,
        cellRender: (row) => {
            return row.user_id
        },
    },
    {
        id: 'amount',
        label: <FormattedMessage id="amount" />,
        cellRender: (row) => {
            return row.amount
        },
    },
    {
        id: 'currency',
        label: <FormattedMessage id="currency" />,
        cellRender: (row) => {
            return row.currency
        },
    },
    {
        id: 'type',
        label: <FormattedMessage id="type" />,
        cellRender: (row) => {
            return row.type
        },
    },
    {
        id: 'processedAt',
        label: <FormattedMessage id="processed-at" />,
        cellRender: (row) => {
            return row.processed_at && fixDateString(row.processed_at)
        },
    },
]
const TransactionList = () => {
    const [data, setData] = useState<ITransactionItem[]>([])
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(defaultPageSize)
    const [total, setTotal] = useState(0)
    const { showError } = useSnackbar()
    const dispatch = useDispatch()
    const [currentQuery, setCurrentQuery] = useState<ITransactionListReq>({
        app: [],
        processedAt: {
            start: '',
            end: '',
        },
    })

    useEffect(() => {
        const fetchListData = async () => {
            if (
                !currentQuery.processedAt.start ||
                !currentQuery.processedAt.end
            ) {
                return
            }
            dispatch(
                setLoader({
                    status: true,
                })
            )
            const res = await getTransactionList({
                page,
                limit: size,
                ...currentQuery,
            })
            if (res.code !== 0) {
                showError(res.message)
            }
            setData(res.data.list || [])

            if (res.data.total) {
                setTotal(res.data.total)
            }
            dispatch(
                setLoader({
                    status: false,
                })
            )
        }
        fetchListData()
    }, [page, size, currentQuery, showError])

    const handleFilterAction = async (values: ITransactionListReq) => {
        setPage(1)
        setSize(defaultPageSize)
        setCurrentQuery(values)
    }

    return (
        <>
            <TabNavi tabs={operationTabs} />
            <ListFilter onAction={handleFilterAction} />
            <DataTable<ITransactionItem>
                data={data}
                cols={headCells}
                uniKey="tx_id"
                title={<FormattedMessage id="transaction-list" />}
                page={page}
                size={size}
                total={total}
                onPageChange={(page) => {
                    setPage(page)
                }}
                onSizeChange={(size) => {
                    setSize(size)
                }}
            />
        </>
    )
}

export default TransactionList
