import axios, { ICommonListRes, IResData } from 'utils/axios'
import { Currency } from 'utils/currency'

export type groupByType = 'NoGroup' | 'Game' | 'Player'
export type periodType = 'day' | 'week' | 'month'

export interface IGetGGRBetsReqForm {
    page?: number
    limit?: number
    partnerId?: string
    app: string
    from: Date | null
    to: Date | null
    groupBy: groupByType
    period: periodType
    gameId?: string
    currency?: Array<keyof typeof Currency>
    userId?: string
    playerId?: string
}
export interface IGetGGRBetsReq {
    page?: number
    limit?: number
    partnerId?: string[]
    app: string[]
    from: string | null
    to: string | null
    groupBy: groupByType
    period: periodType
    gameId?: string[]
    currency?: Array<keyof typeof Currency>
    userId?: string[]
    playerId?: string[]
}

export interface BasicGGRBetsItem {
    date?: string
    period?: string
    GGR?: number
    Bets?: number
    Wins?: number
    BetCount?: number
    WinCount?: number
    AvgBet?: number
    RTP?: number
    key?: string
    value?: string
}

export interface IGGRBetsItem extends BasicGGRBetsItem {
    list: IGGRBetsItem[]
}

type GetGGRBetsRes = IResData<ICommonListRes<IGGRBetsItem[]>>
export const getGGRBets = (params: IGetGGRBetsReq): Promise<GetGGRBetsRes> => {
    return axios.post<IGetGGRBetsReq, GetGGRBetsRes>(
        '/merchant/get-ggr-bets',
        params
    )
}

export interface IGetGGRBetsByMNCReqForm {
    page?: number
    limit?: number
    partnerId?: string
    app: string
    from: Date | null
    to: Date | null
    currency?: Array<keyof typeof Currency>
}
export interface IGetGGRBetsByMNCReq {
    page?: number
    limit?: number
    partnerId?: string[]
    app: string[]
    from: string | null
    to: string | null
    currency?: Array<keyof typeof Currency>
}

export const getGGRBetsByMNC = (
    params: IGetGGRBetsByMNCReq
): Promise<GetGGRBetsRes> => {
    return axios.post<IGetGGRBetsByMNCReq, GetGGRBetsRes>(
        '/merchant/get-ggr-bets-by-month-currency',
        params
    )
}
