import axios, { ICommonListRes, IResData } from 'utils/axios'

export interface IAppListReq {
    partner?: string // partner name
    app?: string // app name
    partnerId?: string // partner id
    page?: number
    limit?: number
}

export interface IAppItem {
    app_key: string
    app_secret: string
    partner_id: string
    partner: string
    app: string
    permission: string
    caller: string
}

export const getAppList = (
    params: IAppListReq
): Promise<IResData<ICommonListRes<IAppItem[]>>> => {
    return axios.post<IAppListReq, IResData<ICommonListRes<IAppItem[]>>>(
        '/merchant/get-app-list',
        params
    )
}
