// third party
import { object, string, date } from 'yup'
import { useFormik } from 'formik'

// project imports
import CommonForm, { IField } from 'ui-component/form'
import MainCard from 'ui-component/cards/MainCard'
import { currencyOptions } from 'utils/currency'
import { useEffect, useState } from 'react'
import {
    fetchAppListOptions,
    selectAppListOptions,
} from 'store/slices/asyncOptions'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'store'
import { IGetGGRBetsByMNCReq, IGetGGRBetsByMNCReqForm } from 'apis/ggr'
import { convertEndDate, convertStartDate } from 'utils/utils'
import dayjs from 'dayjs'
import { Collapse } from '@mui/material'
import { ExpandMore } from 'ui-component/ExpandMore'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface IProps {
    onAction: (values: IGetGGRBetsByMNCReq) => void
}

const validationSchema = object({
    app: string().trim().required(),
    from: date().required(),
    to: date().required(),
})

// ==============================|| KANBAN BACKLOGS - ADD STORY ||============================== //

const ListFilter = ({ onAction }: IProps) => {
    const appListOption = useSelector(selectAppListOptions)
    const dispatch = useDispatch<AppDispatch>()
    const [expanded, setExpanded] = useState(true)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    useEffect(() => {
        dispatch(fetchAppListOptions())
    }, [dispatch])

    const formik = useFormik<IGetGGRBetsByMNCReqForm>({
        initialValues: {
            app: '',
            partnerId: '',
            currency: [],
            from: dayjs().subtract(7, 'day').toDate(),
            to: dayjs().toDate(),
        },
        validationSchema,
        onSubmit: (values, formikHelper) => {
            const { from, to } = values
            const diffMonth = dayjs(to).diff(dayjs(from), 'month')
            const diffDay = dayjs(to).diff(dayjs(from), 'day')
            if (diffDay < 1) {
                const errorMsg = 'to-date must bigger than from-date'
                formikHelper.setFieldError('to', errorMsg)
                formikHelper.setFieldError('from', errorMsg)
                return
            }
            if (diffMonth >= 2) {
                const errorMsg = 'diff of from-to limit not bigger than 2 month'
                formikHelper.setFieldError('to', errorMsg)
                formikHelper.setFieldError('from', errorMsg)
                return
            }
            const filteredFields: IGetGGRBetsByMNCReq = {
                app: [values.app],
                from: convertStartDate(from as Date),
                to: convertEndDate(to as Date),
                currency: values.currency,
            }
            enum transferKeys {
                app = 3,
            }
            Object.keys(values).forEach((ky) => {
                if (
                    typeof values[ky] === 'string' &&
                    values[ky].length > 0 &&
                    transferKeys[ky]
                ) {
                    filteredFields[ky] = values[ky].split(',')
                }
            })
            // console.log(
            //     '🚀 ~ file: ListFilter.tsx:135 ~ ListFilter ~ filteredFields:',
            //     filteredFields
            // )
            setExpanded(false)
            onAction(filteredFields)
        },
    })
    const fields: IField<IGetGGRBetsByMNCReqForm>[] = [
        {
            key: 'app',
            type: 'select',
            required: true,
            option: appListOption,
        },
        {
            key: 'currency',
            type: 'mul-select',
            required: true,
            option: currencyOptions,
        },
        {
            key: 'from',
            required: true,
            type: 'date',
        },
        {
            key: 'to',
            required: true,
            type: 'date',
        },
    ]
    return (
        <MainCard
            title="Filter"
            sx={{ mb: 3 }}
            secondary={
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show filter"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            }
        >
            <Collapse in={expanded} timeout="auto">
                <CommonForm<IGetGGRBetsByMNCReqForm>
                    fields={fields}
                    formik={formik}
                    title=""
                    gridValue={4}
                />
            </Collapse>
        </MainCard>
    )
}
export default ListFilter
