import { ITab } from './TabNavi'

export const operationTabs: ITab[] = [
    {
        label: 'player-list',
        link: '/player-list',
    },
    {
        label: 'round-list',
        link: '/round-list',
    },
    {
        label: 'transaction-list',
        link: '/transaction-list',
    },
]
