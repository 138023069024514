import axios, { ICommonListRes, IResData } from 'utils/axios'

export interface IGameItem {
    game_id: string
    title: string
}

export const getGameId = (): Promise<IResData<ICommonListRes<IGameItem[]>>> => {
    return axios.post<{}, IResData<ICommonListRes<IGameItem[]>>>(
        '/merchant/get-game-config'
    )
}
