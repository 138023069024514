import { OptionObj } from 'types'
export enum Country {
    EN,
    PT,
    ESP,
    JP,
    ID,
    AR,
    DE,
    FI,
    FR,
    KO,
    HI,
    PL,
    RU,
    TR,
    VI,
}
export const countryOptions: OptionObj[] = Object.entries(Country)
    .filter((e) => !isNaN(e[0] as any))
    .map((e) => ({ label: e[1] as string, value: e[1] }))
