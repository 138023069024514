// third party
import { object, string, date } from 'yup'
import { useFormik } from 'formik'

// project imports
import CommonForm, { IField } from 'ui-component/form'
import MainCard from 'ui-component/cards/MainCard'
import { currencyOptions } from 'utils/currency'
import { convertEndDate, convertStartDate } from 'utils/utils'
import { useEffect, useState } from 'react'
import {
    fetchAppListOptions,
    fetchGameIdOptions,
    selectAppListOptions,
    selectGameIdOptions,
} from 'store/slices/asyncOptions'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'store'
import {
    IGetGGRBetsReq,
    IGetGGRBetsReqForm,
    groupByType,
    periodType,
} from 'apis/ggr'
import { groupByOption, periodOption } from './Options'
import dayjs from 'dayjs'
import { Collapse } from '@mui/material'
import { ExpandMore } from 'ui-component/ExpandMore'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
interface IProps {
    onAction: (values: IGetGGRBetsReq) => void
}

const validationSchema = object({
    app: string().trim().required(),
    from: date().required(),
    to: date().required(),
    groupBy: string().trim().required(),
    period: string().trim().required(),
})

// ==============================|| KANBAN BACKLOGS - ADD STORY ||============================== //

const ListFilter = ({ onAction }: IProps) => {
    const appListOption = useSelector(selectAppListOptions)
    const gameIdOption = useSelector(selectGameIdOptions)
    const dispatch = useDispatch<AppDispatch>()
    const [requirement, setRequirement] = useState({
        gameId: false,
        userId: false,
    })
    const [expanded, setExpanded] = useState(true)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    useEffect(() => {
        dispatch(fetchAppListOptions())
        dispatch(fetchGameIdOptions())
    }, [dispatch])

    const formik = useFormik<IGetGGRBetsReqForm>({
        initialValues: {
            app: '',
            gameId: '',
            currency: [],
            userId: '',
            playerId: '',
            partnerId: '',
            from: dayjs().subtract(7, 'day').toDate(),
            to: dayjs().toDate(),
            groupBy: groupByOption[0].value as groupByType,
            period: periodOption[0].value as periodType,
        },
        validationSchema,
        onSubmit: (values, formikHelper) => {
            const { from, to } = values
            const diffDay = dayjs(to).diff(dayjs(from), 'day')
            if (diffDay < 1 || diffDay > 31) {
                const errorMsg = 'limit 1~31s day of from-to'
                formikHelper.setFieldError('to', errorMsg)
                formikHelper.setFieldError('from', errorMsg)
                return
            }
            const filteredFields: IGetGGRBetsReq = {
                app: [values.app],
                from: convertStartDate(from as Date),
                to: convertEndDate(to as Date),
                groupBy: values.groupBy,
                period: values.period,
            }
            if (values.currency && values.currency.length > 0) {
                filteredFields.currency = values.currency
            }
            enum transferKeys {
                app = 3,
                userId = 4,
                playerId = 5,
                gameId = 6,
            }
            Object.keys(values).forEach((ky) => {
                if (
                    typeof values[ky] === 'string' &&
                    values[ky].length > 0 &&
                    transferKeys[ky]
                ) {
                    filteredFields[ky] = values[ky].split(',')
                }
            })
            // console.log(
            //     '🚀 ~ file: ListFilter.tsx:135 ~ ListFilter ~ filteredFields:',
            //     filteredFields
            // )
            setExpanded(false)
            onAction(filteredFields)
        },
    })

    useEffect(() => {
        if (formik.values.groupBy === 'Game') {
            setRequirement({
                userId: false,
                gameId: true,
            })
        } else if (formik.values.groupBy === 'Player') {
            setRequirement({
                userId: true,
                gameId: false,
            })
        } else {
            setRequirement({
                userId: false,
                gameId: false,
            })
        }
    }, [formik.values.groupBy])

    const fields: IField<IGetGGRBetsReqForm>[] = [
        {
            key: 'app',
            type: 'select',
            required: true,
            option: appListOption,
        },
        {
            key: 'gameId',
            type: 'select',
            required: requirement.gameId,
            option: gameIdOption,
        },
        {
            key: 'currency',
            type: 'mul-select',
            option: currencyOptions,
        },
        {
            key: 'groupBy',
            type: 'select',
            required: true,
            option: groupByOption,
        },
        {
            key: 'period',
            type: 'select',
            required: true,
            option: periodOption,
        },
        {
            key: 'userId',
            type: 'input',
            required: requirement.userId,
        },
        {
            key: 'from',
            required: true,
            type: 'date',
        },
        {
            key: 'to',
            required: true,
            type: 'date',
        },
        {
            key: 'playerId',
            type: 'input',
        },
    ]
    return (
        <MainCard
            title="Filter"
            sx={{ mb: 3 }}
            secondary={
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show filter"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            }
        >
            <Collapse in={expanded} timeout="auto">
                <CommonForm<IGetGGRBetsReqForm>
                    fields={fields}
                    formik={formik}
                    title=""
                    gridValue={4}
                />
            </Collapse>
        </MainCard>
    )
}
export default ListFilter
