import { Card, Tab, Tabs } from '@mui/material'
import { SyntheticEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

export interface ITab {
    label: string
    link: string
}
interface IProps {
    tabs: ITab[]
}

const TabNavi = ({ tabs }: IProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const handleChange = (event: SyntheticEvent, link: string) => {
        navigate(link)
    }
    return (
        <Card sx={{ mb: 1 }}>
            <Tabs
                value={location.pathname || ''}
                onChange={handleChange}
                centered
            >
                {tabs.map(({ label, link }) => (
                    <Tab
                        label={<FormattedMessage id={label} />}
                        value={link}
                        key={label}
                    />
                ))}
            </Tabs>
        </Card>
    )
}
export default TabNavi
